<template>
<div>
  <b-container fluid>
    <b-row class="row text-white bg-info">
      <b-col align-self="center" cols="2" class=" cols text-center" align-v="center">
        <h1 justify="center" align="center" class="kstyle title justify-content-md-center">Insights report</h1>
      </b-col>
      <b-col cols="8" align-self="center" align-v="center" class="text-center justify-content-md-center">
      </b-col>
    </b-row>
    <b-row class="my-2">
      <b-col class="text-center">
        <b-button variant="outline-primary" class="mb-2 mr-2" v-b-toggle.sidebar-1>settings</b-button>
        <b-button variant="outline-primary" class="mb-2" @click="generateReport">download report</b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div v-if="articles.length > 0" id="exportElement">
          <div class="mb-3" v-for="item in articles" :key="'publication-' + item.id">
            <publication-report
              :propItem="item"
              :options="options"
            />
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
  <b-sidebar id="sidebar-1" title="Report settings" shadow>
    <div class="px-3 py-2">
      <b-form-checkbox v-model="options.published" :value="true" :unchecked-value="false">published</b-form-checkbox>
      <b-form-checkbox v-model="options.events" :value="true" :unchecked-value="false">events</b-form-checkbox>
      <b-form-checkbox v-model="options.news" :value="true" :unchecked-value="false">news</b-form-checkbox>
      <b-form-checkbox v-model="options.regtopics" :value="true" :unchecked-value="false">regtopics</b-form-checkbox>
      <b-form-checkbox v-model="options.risks" :value="true" :unchecked-value="false">risks</b-form-checkbox>
      <b-form-checkbox v-model="options.tags" :value="true" :unchecked-value="false">tags</b-form-checkbox>
      <b-form-checkbox v-model="options.para" :value="true" :unchecked-value="false">first para only</b-form-checkbox>
    </div>
  </b-sidebar>
</div>
</template>

<script>
import PublicationReport from '@/components/ArticleReport'

export default {
  components: {
    PublicationReport
  },
  computed: {
    articles: {
      get () {
        return this.$store.state.articles
      },
      set (payload) {
        this.$store.commit('setArticles', payload)
      }
    }
  },
  data () {
    return {
      options: {
        events: true,
        news: true,
        para: false,
        published: true,
        regtopics: true,
        risks: true,
        tags: true
      }
    }
  },
  created: async function () {
    await this.$nextTick()
    if (this.articles.length > 0) {
      window.localStorage.setItem('frmCloudArticles', JSON.stringify(this.articles))
    } else {
      if (window.localStorage.getItem('frmCloudArticles') !== null) {
        this.articles = JSON.parse(window.localStorage.getItem('frmCloudArticles'))
      }
    }
  },
  methods: {
    generateReport: function () {
      const start = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>"
      const end = "</body></html>"
      const html = start + document.getElementById('exportElement').innerHTML + end
      // const blob = new Blob(['\ufeff', html], { type: 'application/msword' })
      const url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html)
      const filename = 'insights.doc'
      const link = document.createElement('a')
      link.href = url
      link.download = filename
      link.click()
    }
  },
  watch: {
  }
}
</script>
<style>
</style>
